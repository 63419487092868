import React from 'react';
import {Helmet} from 'react-helmet';

class FraudDisclaimer extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Fraud Disclaimer | Tintash</title>
        </Helmet>
        <section className="privacy__landing-section">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-12">
                <h1 className="privacy__landing-heading">Fraud Disclaimer</h1>
                <p className="privacy__landing-subheading">Last Updated: 31st of March, 2023</p>
                <p className="privacy__landing-description">
                  At Tintash, your privacy is very important to us. Please note that we only
                  communicate through our official website. Any other website or entity claiming to
                  represent us may be fraudulent.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="privacy__content-section">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-10 mx-auto">
                <div className="text-left">
                  <p className="privacy__section-description">
                    It has come to our attention that certain organizations and individuals
                    misrepresenting themselves as agents, officers, or members of Tintash Inc., may
                    approach you and attempt to obtain your personal or financial information by
                    offering fraudulent financing or investment opportunities, services, products,
                    or prizes. This fraud may be perpetuated via an unsolicited email, an online
                    message, a pre-recorded telephone message, or by using a website or social media
                    account that purports to be affiliated with Tintash Inc. These claims and offers
                    are fraudulent and invalid, and you are strongly advised to exercise great
                    caution and disregard such offers and invitations.
                  </p>
                  <p className="privacy__section-description">
                    Tintash does not contact individuals via automated or prerecorded telephone
                    calls, nor do we contact individuals via generic email addresses such as Gmail,
                    Hotmail, or Yahoo. All communications will always originate from a verifiable
                    Tintash source, telephone number, or e-mail address and not from any free
                    web-based pages or email accounts. Additionally, Tintash does not use platforms
                    such as WhatsApp, Telegram or other instant messaging applications to reach out
                    to potential clients or employees. Please take extra caution while examining
                    such web-based pages or email addresses, as the perpetrators may misspell an
                    official Tintash page or email address and use a slightly modified version of an
                    official Tintash email address or the Tintash official web page.
                  </p>
                  <p className="privacy__section-description">
                    Criminal and/or civil liabilities may arise from such actions and we intend to
                    cooperate with competent law enforcement agencies and to ask them to take
                    appropriate action whenever such phenomena occur. If you are unsure whether a
                    communication is legitimate, please send us an email query to{' '}
                    <a href="mailto:bd@tintash.com">bd@tintash.com</a> before taking any action
                    related to such correspondence.
                  </p>
                  <p className="privacy__section-description">
                    Please note that <a href="https://tintash.com">tintash.com</a> and&nbsp;
                    <a href="http://tintash.no/">tintash.no</a> are the only domains representing
                    Tintash. If you receive any correspondence from a similar-looking website or
                    online entity, notify the police or other competent authority and do not share
                    any information with them.
                  </p>
                  <p className="privacy__section-description">
                    Please note that under no circumstances shall Tintash be held liable or
                    responsible for any claims, losses, damages, expenses, or other inconvenience
                    resulting from or in any way connected to the actions of these imposters.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default FraudDisclaimer;
