import React from 'react';
import * as ReactGA from 'react-ga';
import Layout from '../components/Shared/Layout';
import FraudDisclaimer from '../components/fraud_disclaimer';
import '../assets/css/privacy.css';


class FraudPage extends React.Component {
  componentDidMount() {
    ReactGA.set({
      title: 'Fraud.Disclaimer',
    });
  }

  render() {
    return (
      <Layout>
        <FraudDisclaimer />
      </Layout>
    );
  }
}

export default FraudPage;
